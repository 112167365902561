
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import authSetting from '~/config/authSetting'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import { errorHandler } from '~/lib/errorHandling'

@Component({
  mixins: [MixinMemberId],
  data() {
    return {
      localFollowing: false // 表示用のfollow data
    }
  }
})
export default class Follow extends Vue {
  @Prop({ type: String, required: true }) private followText!: string
  @Prop({ type: String, required: true }) private type!: string
  @Prop({ type: Object, required: true }) private item!: any
  @Prop({ type: Boolean, default: false }) private card!: boolean
  @Prop({ type: String }) private used!: string

  data() {
    return {
      localFollowing: false
    }
  }

  private memberId!: number
  private localFollowing: boolean = false
  private isToggling: boolean = false

  // storeのmemberFollowingに変化があったらlocalFollowingを更新
  @Watch('$store.state.member.memberFollowing', {
    immediate: true,
    deep: true
  })
  onMemberFollowingChange() {
    this.updateLocalFollowing()
  }

  // mount時にstoreのmemberFollowingが取得済みか確認、未取得の場合はwatchで後から取得されるのを待つ
  private mounted() {
    if (this.$store.state.member.memberFollowing) {
      this.updateLocalFollowing()
    } else {
      this.$watch(
        () => this.$store.state.member.memberFollowing,
        () => {
          this.updateLocalFollowing()
        },
        { immediate: true, deep: true }
      )
    }
  }

  private get isFollow(): boolean {
    return this.localFollowing
  }

  private updateLocalFollowing() {
    if (this.isToggling) return // トグル中ならスキップ

    const memberFollowing = this.$store.state.member.memberFollowing
    if (!memberFollowing || !memberFollowing.following) {
      this.localFollowing = false
      return
    }

    const key = this.type === 'company' ? 'companies' : 'categories'
    const targetArray = memberFollowing.following[key]
    if (!Array.isArray(targetArray)) {
      this.localFollowing = false
      return
    }

    this.localFollowing = targetArray.some(
      (target: { id: number }) => target.id === this.item.id
    )
  }

  private toggleFollow(event: Event): void {
    event.stopPropagation()
    const previewPath = /^\/preview\//.test(this.$route.path)
    if (!previewPath && !this.memberId) {
      this.$store.dispatch('modal/changeNonMemberModalState', true)
    } else if (!previewPath) {
      const beforeFollowed = this.isFollow // APIコール前にlocalFollowingの値を変更するので、トグル前の状態を記録
      this.isToggling = true
      this.localFollowing = !beforeFollowed
      beforeFollowed ? this.delete() : this.submit()
    }
  }

  private async delete(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      const token = await this.$axios.$get(csrfUrl, {
        auth: authSetting,
        withCredentials: true
      })
      await this.deleteAction(token.csrf_token)
    } catch (err) {
      this.localFollowing = true
      this.isToggling = false
    }
  }

  private async deleteAction(csrf: string): Promise<void> {
    const url =
      this.type === 'company'
        ? process.env.API_URL + `/members/${this.memberId}/follows`
        : process.env.API_URL + `/members/${this.memberId}/category_follows`
    const deleteData = {
      [`${this.type}_id`]: this.item.id
    }
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      data: deleteData,
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios.$delete(url, config)
      this.localFollowing = false
      this.$store.dispatch('member/updateFollowing', {
        item: this.item,
        type: this.type,
        flag: 'remove'
      })
    } catch {
      this.localFollowing = true
    } finally {
      this.isToggling = false
    }
  }

  private async submit(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      const token = await this.$axios.$get(csrfUrl, {
        auth: authSetting,
        withCredentials: true
      })
      await this.submitAction(token.csrf_token)
    } catch (err) {
      errorHandler(err, this.$nuxt.context)
    }
  }

  private async submitAction(csrf: string): Promise<void> {
    const url =
      this.type === 'company'
        ? process.env.API_URL + `/members/${this.memberId}/follows`
        : process.env.API_URL + `/members/${this.memberId}/category_follows`
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    const postData = {
      [`${this.type}_id`]: this.item.id
    }
    try {
      await this.$axios.$post(url, postData, config)
      this.localFollowing = true
      this.$store.dispatch('member/updateFollowing', {
        item: this.item,
        type: this.type,
        flag: 'add'
      })
    } catch {
      this.localFollowing = false
    } finally {
      this.isToggling = false
    }
  }
}
